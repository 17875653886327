import React from "react"
import { graphql } from "gatsby"
import Template from "../components/Template"
import Video from "../components/Video"

const ContentsDetails = ({ data }) => {
  const { html } = data.markdownRemark
  const { title, language, trad, videoURL } = data.markdownRemark.frontmatter

  return (
    <Template title={title} language={language} trad={trad}>
      {videoURL && <Video url={videoURL} />}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Template>
  )
}

export default ContentsDetails

export const query = graphql`
  query ContentsDetails(
    $slug: String
    $language: String
    $project: String
    $videoURL: String
    $trad: Boolean
  ) {
    markdownRemark(
      frontmatter: {
        slug: { eq: $slug }
        language: { eq: $language }
        project: { eq: $project }
        trad: { eq: $trad }
        videoURL: { eq: $videoURL }
      }
    ) {
      html
      frontmatter {
        title
        language
        trad
        videoURL
      }
    }
  }
`
