import React from "react";
import { Helmet } from "react-helmet";

function SEO({ description, lang, title }) {

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name={"description"} content={description} />
      <meta property="og:type" content="article" />
    </Helmet>
  )
};

SEO.defaultProps = {
  lang: `fr`,
  description: `Améliorer la prise en charge et le suivi thérapeutique avec l’application Curecall, obtenez une visibilité sur l’état de santé de vos patients`,
  title: `Curecall`,
}

export default SEO;
