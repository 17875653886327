import Hls from "hls.js"
import React, { useEffect } from "react"

const Video = ({ url }) => {
  const fullUrl = url + "#t=0.1"

  useEffect(() => {
    var video = document.getElementById("video")

    if (Hls.isSupported()) {
      var hls = new Hls()
      hls.loadSource(fullUrl)
      hls.attachMedia(video)
    }
    // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
    // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
    // This is using the built-in support of the plain video element, without using hls.js.
    else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = fullUrl
      video.addEventListener("canplay", function () {
        video.play()
      })
    }
  }, [])

  return <video id="video" controls preload="metadata"></video>
}

export default Video
