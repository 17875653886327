import React from "react"

const RoundedButton = ({ label, color, onClick }) => (
  <button
    className="rounded-button"
    style={{ backgroundColor: color }}
    onClick={onClick}
  >
    <p>{label}</p>
  </button>
)

export default RoundedButton
