import React, { useState } from "react"
import Layout from "./Layout"
import RoundedButton from "./RoundedButton"
import SEO from "./SEO"

const Template = ({ children, title, language, trad }) => {
  const [fontSize, setFontSize] = useState(1)

  function fontBigger() {
    setFontSize(fontSize + 0.1)
  }

  function fontSmaller() {
    setFontSize(fontSize - 0.1)
  }

  return (
    <Layout
      language={language}
      trad={trad}
      fontBigger={() => fontBigger()}
      fontSmaller={() => fontSmaller()}
    >
      <SEO title={title} lang={language} />
      <div className="content-details" style={{ "--font-scale": fontSize }}>
        <h1>{title}</h1>
        {children}
      </div>
      <div style={{ textAlign: "center" }}>
        <RoundedButton
          onClick={() => {
            window.scrollTo(0, 0)
          }}
          label="Haut de page"
        />
      </div>
      <hr />
    </Layout>
  )
}

export default Template
